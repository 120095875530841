@charset "utf-8";
/* CSS Document */

@import url("https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900");

body {
  padding: 0;
  margin: 0;
  color: #30323f;
  font-family: "Titillium Web", sans-serif;
}
body .bg {
  background: url(../images/loginBg.jpg) no-repeat top;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 950px;
}

div,
p,
form,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
form,
img,
br,
input,
textarea,
label,
samp {
  padding: 0;
  margin: 0;
}

a,
input {
  text-decoration: none;
  outline: none;
}

img {
  border: 0;
  outline: none;
}

ul {
  list-style-type: none;
}

*:focus {
  outline: none;
}
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input,
textarea {
  background: transparent;
  padding: 0px 0 0px 0;
  border: 0;
  width: 100%;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: none !important;
}
/* input::-webkit-input-placeholder {color: red;} 
input:-moz-placeholder {color: red;} 
input::-moz-placeholder {color: red;} 
input:-ms-input-placeholder {color: red;}
textarea::-webkit-input-placeholder {color: green;} 
textarea:-moz-placeholder {color: green;} 
textarea::-moz-placeholder {color: green;} 
textarea:-ms-input-placeholder {color: green;}
input[type="email"]::-webkit-input-placeholder {color: blue;} 
input[type="email"]:-moz-placeholder {color: blue;} 
input[type="email"]::-moz-placeholder {color: blue;} 
input[type="email"]:-ms-input-placeholder {color: blue;} */

input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.clear {
  clear: both;
  line-height: 0 !important;
  font-size: 0 !important;
  height: 0px !important;
}

.left {
  float: left;
}
.right {
  float: right !important;
}
.block {
  display: block;
}
.inlineblock {
  display: inline-block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.bdrT {
  border-top: 1px solid #dadada;
}
.bdrB {
  border-bottom: 1px solid #b5b5b5;
}

.transition {
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
.transition2 {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.transition3 {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
/*  -webkit-transform: scaleX(1); transform: scaleX(1); -webkit-transition-delay:0.5s;  transition-delay:0.5s; */
/*------------------------------------------------------------------*/

.top_panel {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 82px;
  -webkit-box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.2);
}
.top_panelMoved {
  left: -165px;
}

.top_panel div.logo {
  float: left;
}
.top_panel div.logo {
  float: left;
  height: 100%;
  vertical-align: middle;
  padding-left: 90px;
  padding-top: 0px;
}
.top_panel div.logo:before {
  content: "\200B";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.top_panel div.logo img {
  display: inline-block;
  vertical-align: middle;
}

/*.navTrigger{background:url(../images/navTrigger.png) no-repeat 50% 0; width:35px; height:35px; text-indent:-9999999999px; overflow:hidden; cursor:pointer; float:right; margin:12px 15px 0 0; display:none; }
.navTriggerActive{background:url(../images/navTrigger.png) no-repeat 50% 100%;}*/

.top_panel nav {
  float: right;
  padding: 0px 70px 0 0;
}
.top_panel ul#nav li {
  float: left;
  padding: 0 18px;
  height: 62px;
}
.top_panel ul#nav li.navActive {
  border-bottom: 3px solid #ed1c24;
}
.top_panel ul#nav li a {
  color: #fff;
  font-size: 15px;
  line-height: 62px;
  display: block;
  height: 100%;
}
.top_panel ul#nav li a:hover {
  color: #fff;
  text-transform: none;
}
.top_panel h1 {
  display: none;
}
/*.top_panel ul#nav li:nth-child(1){background:url(../images/login.png) no-repeat left center; padding:0 15px 0 15px;}
.top_panel ul#nav li:nth-child(2){background:url(../images/signup.png) no-repeat left center; padding:0 15px 0 23px;}
.top_panel ul#nav li:nth-child(3){background:url(../images/more.png) no-repeat left center; padding:0 15px 0 15px;}*/

.top_panel ul#nav li.signInBtn {
  padding: 0 0px !important;
}
.top_panel ul#nav li.signInBtn a {
  background: #ed1c24;
  color: #fff;
  padding: 0 25px 0 42px;
  position: relative;
  font-weight: 600;
  height: 62px;
  line-height: 62px;
}
/*.top_panel ul#nav li.signInBtn a:after{content:''; position:absolute; left:22px; top:23px; width:12px; height:16px; background:url(../images/signIn-icon.png) no-repeat 50% 50%;}*/

/*.bg{background:url(../images/loginBg.jpg) no-repeat top; background-size:cover; }
.bg2a {background: url(../images/login-bg.jpg) no-repeat 50% 50%; background-size: cover;}*/
.left-part2 {
  float: left;
  width: 25%;
  text-align: left;
  margin: 0 0 25px 0;
}
.right-part2 {
  float: left;
  width: 75%;
}
.right-part2 ul li {
  float: left;
  width: 70px !important;
  font-size: 14px;
}
.clr {
  clear: both;
}
.cc {
  margin-top: 25px !important;
}
.right-part2 ul li select {
  width: 60px;
  border-left: none;
  border-right: none;
  border-top: none;
}
.right-part2 ul li input[type="text"] {
  width: 60px;
  border-left: none;
  border-right: none;
  border-top: none;
}
.loginSec1 {
  vertical-align: middle;
  text-align: center;
  padding: 32px 0 0;
  position: relative;
}

.cc .styled-input .dd input {
  font-size: 14px;
  color: #30323f;
  position: relative;
  top: 0px;
  border-bottom: 1px solid #58ae68;
  height: auto !important;
  padding-left: 0 !important;
  text-align: left;
}

/*.loginSec1:before{content:''; width: 40px; height:40px; background:url(../images/scroll-icon.png) no-repeat 0 0; position:absolute; left:0; right:0; bottom:20px; margin:auto;}*/
.loginSec1:after {
  content: "\200B";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.loginSec1_cont {
  display: inline-block;
  vertical-align: top;
  width: 95%;
  max-width: 1090px;
}

.loginIntro {
  float: left;
  width: 610px;
  text-align: left;
  padding: 230px 0 0 0;
}
.loginIntro:before {
  content: "";
  width: 20px;
  height: 2px;
  display: block;
  border-bottom: 3px solid #adffc5;
  margin-bottom: 25px;
}
.loginIntro h2 {
  font-size: 60px;
  color: #fff;
  line-height: 60px;
  font-weight: 400;
  margin-bottom: 15px;
}
.loginIntro h2 span {
  font-size: 60px;
  color: #000;
  line-height: 60px;
  font-weight: 600;
  display: block;
}

.loginIntro ul:after {
  content: "";
  display: block;
  display: block;
}
.loginIntro ul li {
  float: left;
  position: relative;
  height: 105px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  padding: 65px 20px 0;
  font-size: 16px;
  color: #fff;
}
.loginIntro ul li:first-child {
  border: 0;
}
.loginIntro ul li:after {
  width: 45px;
  height: 45px;
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  line-height: 45px;
  text-align: center;
  font-size: 20px;
  color: #000;
  margin: auto;
  border-radius: 50%;
  font-weight: 600;
}
/*.loginIntro ul li:nth-of-type(1):after{content:'E'; background:#fff46d;}
.loginIntro ul li:nth-of-type(2):after{content:'A'; background:#86c779;}
.loginIntro ul li:nth-of-type(3):after{content:'T'; background:#53b7eb;}*/

.loginBox {
  float: right;
  background-color: #fff;
  border-radius: 10px;
  width: 345px;
  height: 420px;
  position: relative;
  overflow: hidden;
  margin: 110px 0 0 0;
}
.loginBox > div {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 30px;
  height: 100%;
}
.loginBox > div.signIn {
  position: relative;
  z-index: 1;
}
.loginBox > div.password_reset {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
}

.loginBox > div.password_reset_success {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 3;
}
.loginBox > div.password_reset_success > div {
  padding-top: 120px;
  position: relative;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
}
.loginBox > div.password_reset_success > div:before {
  content: "";
  width: 90px;
  height: 90px;
  border: 1px solid #e6e6e6;
  border-radius: 50%; /*background:url(../images/mailIcon.png) no-repeat 50% 50%; */
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  margin: auto;
}
.loginBox > div.password_reset_success > div strong {
  display: block;
  padding-top: 10px;
}

.loginBox > div.signInHide {
  margin-left: -100%;
}
.loginBox > div.forgot_password_show {
  left: 0;
}
.loginBox > div.password_reset_success_show {
  left: 0;
}

.fp_close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: url(../images/close.png) no-repeat 50% 50%;
  color: transparent;
  font-size: 0;
  cursor: pointer;
}

.loginBox h3 {
  font-size: 24px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: left;
  position: relative;
}
.loginBox p {
  font-size: 12px;
  color: #30323f;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: left;
  line-height: 12px;
}
.loginBox h3:before {
  content: "";
  width: 30px;
  border-bottom: 2px solid #3c3784;
  position: absolute;
  left: 0px;
  top: 40px;
}
.green {
  padding: 15px 0 0 0;
  color: #006600;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.signinForm {
  margin-top: 45px;
}

.submitBtn {
  background: #3c3784;
  padding: 0px 15px;
  display: inline-block;
  border-radius: 23px;
  text-align: left;
  color: #fff;
  line-height: 47px;
  height: 47px;
  display: block;
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  left: 30px;
  bottom: 57px;
  width: 285px;
}
.submitBtn span {
  display: inline-block;
  padding-right: 45px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  position: relative;
}
.submitBtn span:after {
  width: 36px;
  height: 13px;
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 17px;
  content: "";
  line-height: 47px;
  display: block;
  background: url(../images/arrow2.png) 100% 50% no-repeat;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 0.5s ease;
}
.submitBtn:hover span:after {
  background: url(../images/arrow2.png) 0 50% no-repeat;
}
.submitBtn input {
  text-transform: uppercase;
  border: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: transparent;
  color: transparent;
  text-align: left;
  cursor: pointer;
  padding-left: 20px;
}

.signinForm:after {
  content: "";
  display: block;
  clear: both;
}
.signinForm li {
  float: left;
  width: 100%;
  position: relative;
}
.signinForm li.unIcon:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 20px;
  height: 20px;
  background: url(../images/from-icon.png) no-repeat 50% 0px;
}
.signinForm li.passIcon:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 20px;
  height: 20px;
  background: url(../images/from-icon.png) no-repeat 50% -20px;
}
.signinForm li.captcha:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 20px;
  height: 20px;
  background: url(../images/from-icon.png) no-repeat 50% -40px;
}
.signinForm li.emailIcon:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 20px;
  height: 20px;
  background: url(../images/from-icon.png) no-repeat 50% -80px;
}
.signinForm li.captcha {
  float: left;
  width: 50%;
}
.signinForm li.captchaImg {
  float: left;
  width: 50%;
  border-bottom: none;
  padding: 20px 0 0;
  margin: 0;
}
.signinForm li.captchaImg:after {
  content: "";
  display: block;
  clear: both;
}
.signinForm li.captchaImg img {
  float: left;
  position: relative;
  top: -8px;
}
.signinForm li.captchaImg a.change_captcha {
  float: left;
  color: #3676bc;
  font-size: 10px;
  width: 30px;
  height: 20px;
  background: url(../images/from-icon.png) no-repeat 50% -60px;
  font-size: 0;
  color: transparent;
  margin-left: 10px;
}

input:focus ~ label,
textarea:focus ~ label,
input:valid ~ label,
textarea:valid ~ label {
  font-size: 11px;
  color: #333;
  top: -12px;
  -webkit-transition: all 0.125s ease;
  transition: all 0.125s ease;
}
.styled-input input:focus,
.styled-input textarea:focus,
.styled-input input:valid,
.styled-input textarea:valid {
  border-bottom: 1px solid #213468;
}
.styled-input {
  width: 100%;
  margin: 5px 0 15px;
  position: relative;
  padding: 0px 0 0 0px;
}
.styled-input label {
  font-size: 16px;
  color: #000;
  position: absolute;
  top: 11px;
  left: 25px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
  font-weight: 400;
}
.styled-input input {
  font-size: 16px;
  color: #30323f;
  position: relative;
  top: 0px;
  border-bottom: 1px solid #3c3784;
  height: 40px;
  padding-left: 25px;
}
.forgot_pass {
  color: #000;
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
  width: 110px;
  margin: auto;
  cursor: pointer;
  font-weight: 600;
}
.signinForm .errorlog {
  padding: 10px 0 0 0;
  text-align: center;
  color: #ed1c24;
  font-size: 14px;
  line-height: 20px;
}
.errorMsg {
  position: absolute;
  top: 39px;
  right: 0;
  color: #ed1c24;
  font-size: 12px;
  width: 100%;
  border-top: 1px solid #ed1c24;
  text-align: right;
}
/*.errorMsg:after{content:''; position:absolute; right:0; top:-25px; width:18px; height:25px; background:url(../images/error-icon.png) no-repeat 0 0;}*/

.login_usp_sec {
  padding: 60px 0;
}
.center {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 0;
}
.usp h2 {
  display: block;
  padding-bottom: 35px;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  color: #30323f;
  font-weight: 400;
}
.usp ul {
  text-align: center;
  vertical-align: top;
}
.usp ul li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 260px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  margin: 0 12px 25px;
  padding: 100px 25px 25px;
  text-align: left;
  overflow: hidden;
  color: #30323f;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  -webkit-box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.1);
}
.usp ul li:after {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 1;
}
/*.usp ul li.uspIcon1:after{ background:url(../images/usp-icons.png) no-repeat 0 0;}
.usp ul li.uspIcon2:after{ background:url(../images/usp-icons.png) no-repeat 0 -50px;}
.usp ul li.uspIcon3:after{ background:url(../images/usp-icons.png) no-repeat 0 -100px;}
.usp ul li.uspIcon4:after{ background:url(../images/usp-icons.png) no-repeat 0 -150px;}
.usp ul li.uspIcon5:after{ background:url(../images/usp-icons.png) no-repeat 0 -200px;}*/
.usp ul li a.more {
  position: absolute;
  left: 25px;
  bottom: 25px;
  color: #3676bc;
  font-size: 10px;
  line-height: 22px;
  text-transform: uppercase;
}
.usp ul li a.more:hover {
  color: #e50828;
}
.usp ul li a.more:before {
  content: "\002B";
  display: inline-block;
  font-size: 15px;
  margin-right: 3px;
  position: relative;
  top: -2px;
  vertical-align: top;
  color: #ed1c24;
}
.usp ul li div.uspPop {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ed1c24;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  z-index: 2;
  vertical-align: middle;
  text-align: center;
}
.usp ul li div.uspPop span {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: left;
}
/* .usp ul li div.uspPop:after{content:'\200B'; display:inline-block; height:100%; vertical-align:middle;} */

.content_panel {
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.content_panel .body-part {
  width: 1280px;
  margin: 60px auto 0 auto;
}
.content_panel .body-part h1 {
  text-align: center;
  font-style: 25px;
  text-transform: uppercase;
  color: #000;
  padding: 50px 0 0 0;
}
.content_panel .body-part h1:after {
  content: "";
  display: block;
  border-bottom: 4px solid #0d8924;
  text-align: center;
  margin: 0 auto;
  width: 50px;
  padding: 5px 0 0 0;
  vertical-align: top;
}
.content_panel .body-part ul {
  padding: 40px 0;
  margin: 0 auto;
  text-align: center;
}
.content_panel .body-part ul li {
  width: 28%;
  margin: 0 4% 0 0;
  display: inline-block;
}
.content_panel .body-part ul li:last-child {
  margin: 0 0 0 0;
}
.content_panel .body-part ul li article {
  text-align: left;
}
.content_panel .body-part ul li article h3 {
  font-size: 22px;
  color: #006600;
  padding: 8px 0;
  line-height: 22px;
}
.content_panel .body-part ul li article p {
  font-size: 18px;
  color: #000;
  line-height: 23px;
}
.content_panel .body-part ul li article a {
  padding: 0px 0 0 0;
  display: block;
  color: #006600;
  position: relative;
  width: 97px;
  line-height: 7px;
  margin: 30px 0 0 0;
  display: block;
  text-decoration: none;
}
/*.content_panel .body-part ul li article a:after{ content:""; display:block; background: url(../images/arrow-read.png) center right no-repeat; width:12px; height:10px; position:absolute; top:0px; right:0px;}*/

.content_panel .body-part-privacy {
  width: 1280px;
  margin: 0px auto 50px auto;
  background: #f5f5f5;
  padding: 90px;
  min-height: 520px;
}
.content_panel .body-part-privacy article {
  width: 60%;
  float: left;
}
.content_panel .body-part-privacy article h1 {
  font-size: 48px;
  line-height: 48px;
  color: #0d8924;
  font-weight: 600;
  padding: 0 0 40px 0;
}
.content_panel .body-part-privacy article p {
  font-size: 18px;
  color: #000;
  line-height: 23px;
  padding: 0 0 25px 0;
}
.content_panel .body-part-privacy aside {
  width: 30%;
  float: right;
  padding: 80px 0 0 0;
}
.content_panel .body-part-privacy a {
  position: relative;
  width: 97px;
  line-height: 7px;
  margin: 30px 0 0 0;
  display: block;
  text-decoration: none;
  color: #006600;
}
/*.content_panel .body-part-privacy a:after{ content:""; display:block; background: url(../images/arrow-read.png) center right no-repeat; width:12px; height:10px; position:absolute; top:0px; right:0px;}*/

.content_panel .body-part {
  margin: 0 auto;
  text-align: center;
}
.content_panel .body-part article {
  display: inline-block;
  width: 100%;
  text-align: left;
  padding: 0px 0;
}
.content_panel .body-part .about {
  width: 330px;
  text-align: left;
}

.content_panel .about-part article {
  display: inline-block;
  width: 34%;
  text-align: left;
  padding: 40px 0;
}
.content_panel .about-part article h2 {
  font-size: 40px;
}
.content_panel .about-part article a {
  font-size: 16px;
  color: #0d8924;
  padding: 0px 0 0 0;
  display: block;
  position: relative;
  width: 97px;
  line-height: 7px;
  margin: 50px 0 0 0;
  display: block;
  text-decoration: none;
  color: #006600;
}

/*.content_panel .about-part article a:after{ content:""; display:block; background: url(../images/arrow-read.png) center right no-repeat; width:12px; height:10px; position:absolute; top:0px; right:0px;}
*/

.content_panel .about-part .signup {
  width: 350px;
  height: 125px;
  background: #f6f6f6;
  text-align: left;
  margin: 10px 94px 50px 0;
  padding: 30px;
  float: left;
  vertical-align: top;
}
.content_panel .about-part .signup a h3 {
  font-size: 43px;
  font-weight: 900;
  color: #0d8924;
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
  line-height: 27px;
}
.content_panel .about-part .signup a p {
  font-size: 36px;
  font-weight: 400;
  color: #0d8924;
  padding: 0px;
  margin: 0px;
  line-height: 46px;
}
.signin-center {
  width: 890px;
  margin: 0 auto;
}
.clr {
  clear: both;
}

/*.usp ul li div.uspPop samp{display:block; position:absolute; top:10px; right:15px; width:25px; height:25px; color:#FFF; font-size:35px; cursor:pointer; text-align:right;background:url(../images/close2.png) no-repeat 100% 100%;}
*/

/*FOOOTER*/
.footer-area {
  border-top: 1px solid #d1d1d1;
  background: #e7e7e7;
  color: #333333;
}
.footer {
  padding: 0px 0px 60px;
}
.footer ul li i {
  padding-right: 10px;
  color: #000;
}
.footer-copy {
  background: #adadad;
  border-top: 0.051px solid rgba(162, 162, 162, 0.46);
  padding-bottom: 0px;
  padding-bottom: 7px;
  padding-top: 7px;
}
.single-footer {
  margin-top: 35px;
}
.footer img {
  margin-bottom: 20px;
}
.footer p {
  line-height: 25px;
  font-size: 13px;
  padding-bottom: 8px;
  color: #333333;
}
.footer h4 {
  color: #0d8924;
  font-weight: 600;
  margin-top: 0px;
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid #cdcdcd;
  line-height: 35px;
  margin-bottom: 0px;
  letter-spacing: 2px;
}
.footer-title-line {
  width: 100px;
  height: 1px;
  background: #0d8924;
  margin: 0px 0px 15px;
  position: relative;
  top: -1px;
}
.footer h5 {
  color: #fff;
  margin-top: 20px;
  font-weight: 700;
}
.footer a {
  color: #fff;
}
.footer ul {
  padding: 0px;
  margin: 0px;
}
.footer ol,
.footer ul {
  list-style: outside none none;
}

.footer ul li {
  margin-bottom: 0px;
}
.footer ul.footer-menu li {
  margin-bottom: 0px;
  width: 50%;
  float: left;
}

ul.footer-adress li {
  margin-bottom: -4px;
}
.footer ul li a {
  color: #333333;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}
.footer ul li a:hover {
  color: #0d8924;
  text-decoration: none;
}
.footer-menu li {
  border-bottom: 0px solid rgba(255, 171, 0, 0.31);
}

.footer-copy span {
  margin-right: 20px;
  font-size: 13px;
  color: #fff;
}
.pull-right p {
  color: #fff;
}
.footer-copy span a {
  color: #000;
  font-weight: 500;
}

ul.footer-blog li {
  width: 100%;
  display: table;
  margin-bottom: 0px;
  min-height: 75px;
}
.footer-blog img {
  width: 100%;
  height: 60px;
  margin-bottom: 0px;
}
.footer-blog .blg-entry h6 a {
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 600;
  color: #ffa600;
}
.footer-blog .blg-entry h6 {
  padding: 2px 0px;
  margin: 0px;
}
.footer-blog .blg-date {
  color: #fff;
  background-color: #ad7206;
  padding: 2px 3px;
  font-size: 11px;
  position: relative;
  top: -22px;
  width: 100%;
}
.footer-blog p {
  line-height: 17px;
  padding: 8px 2px;
}
.social ul li {
  float: left;
  padding: 0 3px;
}
.signup a {
  text-decoration: none;
  text-decoration: none;
}
.signup a h3 {
  padding: 0px 0 0 0;
  display: block;
  color: #006600;
  position: relative;
  width: 210px;
  line-height: 7px;
  margin: 30px 0 0 0;
  display: block;
  text-decoration: none;
}
/*.signup a h3:after{ content:""; display:block; background: url(../images/arrow-read2.png) center right no-repeat; width:40px; height:28px; position:absolute; top:0px; right:0px;}*/
/* -- footer end -- */

.loginContCommonSec {
  background: url(../images/loginBg.jpg) no-repeat 50% 50%;
  background-size: cover;
  padding: 100px 0 60px;
}
.loginContCommonSec h2 {
  font-size: 24px;
  padding-bottom: 15px;
  margin-bottom: 30px;
  color: #fff;
  line-height: 28px;
  font-weight: 600;
  position: relative;
}
.loginContCommonSec h2:after {
  content: "";
  width: 20px;
  height: 2px;
  display: block;
  border-bottom: 2px solid #ed1c24;
  position: absolute;
  left: 0;
  bottom: 0;
}
.loginContCommonSec article {
  font-size: 20px;
  color: #fff;
  line-height: 24px;
  max-width: 850px;
  margin-bottom: 25px;
}
.loginContCommonSec article:last-of-type {
  margin-bottom: 0px;
}
.loginContCommonSec article strong {
  display: block;
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 15px;
  font-weight: 600;
}

.aboutGpsSec2 {
  padding: 50px 0;
  background: #fafafa;
  border-bottom: 1px solid #ebebeb;
}
.aboutGpsTab {
  border-radius: 5px;
  margin-bottom: 30px;
}
.aboutGpsTab:after {
  content: "";
  display: block;
  clear: both;
}
.aboutGpsTab li {
  float: left;
  width: 25%;
  border-bottom: 0px solid #dbdbdb;
  text-align: center;
  padding: 35px 0 20px;
  font-size: 20px;
  cursor: pointer;
  position: relative;
}
.aboutGpsTab li:before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
/*.aboutGpsTab li:nth-of-type(1):before{ background:url(../images/aboutGpsTabIcon.png) no-repeat 0 0;}
.aboutGpsTab li:nth-of-type(2):before{ background:url(../images/aboutGpsTabIcon.png) no-repeat -30px 0;}
.aboutGpsTab li:nth-of-type(3):before{ background:url(../images/aboutGpsTabIcon.png) no-repeat -60px 0;}
.aboutGpsTab li:nth-of-type(4):before{ background:url(../images/aboutGpsTabIcon.png) no-repeat -90px 0;}
.aboutGpsTab li.aboutGpsTabActive:nth-of-type(1):before{ background:url(../images/aboutGpsTabIcon.png) no-repeat 0 -30px;}
.aboutGpsTab li.aboutGpsTabActive:nth-of-type(2):before{ background:url(../images/aboutGpsTabIcon.png) no-repeat -30px -30px;}
.aboutGpsTab li.aboutGpsTabActive:nth-of-type(3):before{ background:url(../images/aboutGpsTabIcon.png) no-repeat -60px -30px;}
.aboutGpsTab li.aboutGpsTabActive:nth-of-type(4):before{ background:url(../images/aboutGpsTabIcon.png) no-repeat -90px -30px;}*/
.aboutGpsTab li span {
  font-size: 12px;
  color: #999;
  display: block;
}
.aboutGpsTab li img {
  width: 100%;
  max-width: 185px;
}
.aboutGpsTab li.aboutGpsTabActive {
  border-bottom: 1px solid #ed1c24;
  color: #000;
}
/*.aboutGpsTab li.aboutGpsTabActive:after{content:''; width:15px; height:10px; position:absolute; left:0; right:0; top:100%; margin:auto; background:url(../images/downArrow3.gif) no-repeat 0 0;}
*/
.aboutGpsTabCont {
  border: 1px solid #ebebeb;
  background: #fff;
  padding: 35px 30px;
  -webkit-box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.1);
}
.aboutGpsTabCont ul:after {
  content: "";
  display: block;
  clear: both;
}
.aboutGpsTabCont ul li {
  float: left;
  width: 33.3333333333333%;
  padding: 0 35px;
  border-right: 1px solid #ebebeb;
}
.aboutGpsTabCont ul li:first-of-type {
  padding-left: 0;
}
.aboutGpsTabCont ul li:last-of-type {
  padding-right: 0;
  border-right: 0px solid #ebebeb;
}
.aboutGpsTabCont ul li h3 {
  margin-bottom: 15px;
  color: #30323f;
  font-size: 18px;
  font-weight: 400;
  height: 30px;
}
.aboutGpsTabCont ul li p {
  margin-bottom: 15px;
  color: #30323f;
  font-size: 14px;
  line-height: 18px;
}

.aboutGpsSec3 {
  padding: 50px 0;
}
.aboutGpsSec3 h2 {
  text-align: center;
  color: #30323f;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
}
.aboutGpsFeature_slide:after {
  content: "";
  display: block;
  clear: both;
}
.aboutGpsFeature_slide section {
  float: left;
  width: 33.3333333333333333%;
  padding: 25px 30px 0 112px;
  position: relative;
}
.aboutGpsFeature_slide section:after {
  content: "";
  width: 83px;
  height: 83px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}
/*.aboutGpsFeature_slide section.agf_icon1:after{ background:#083757 url(../images/feature-icon.png) no-repeat 0 0;}
.aboutGpsFeature_slide section.agf_icon2:after{ background:#3fadad url(../images/feature-icon.png) no-repeat -83px 0;}
.aboutGpsFeature_slide section.agf_icon3:after{ background:#25aae1 url(../images/feature-icon.png) no-repeat -166px 0;}
.aboutGpsFeature_slide section.agf_icon4:after{ background:#ef9553 url(../images/feature-icon.png) no-repeat -249px 0;}
.aboutGpsFeature_slide section.agf_icon5:after{ background:#e50828 url(../images/feature-icon.png) no-repeat -332px 0;}*/
.aboutGpsFeature_slide section.agf_icon1 strong {
  color: #083757;
}
.aboutGpsFeature_slide section.agf_icon2 strong {
  color: #3fadad;
}
.aboutGpsFeature_slide section.agf_icon3 strong {
  color: #25aae1;
}
.aboutGpsFeature_slide section.agf_icon4 strong {
  color: #ef9553;
}
.aboutGpsFeature_slide section.agf_icon5 strong {
  color: #e50828;
}
.aboutGpsFeature_slide section strong {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
  display: block;
}

.list li {
  position: relative;
  color: #30323f;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
  padding-left: 12px;
}
.list li a {
  color: #30323f;
  font-size: 14px;
}
.list li:after {
  content: "";
  width: 3px;
  height: 3px;
  background: #ed1c24;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 8px;
}

.fsControlHolder {
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 5;
  vertical-align: top;
}

.banner-dots {
  display: inline-block;
}
.banner-dots li {
  display: inline-block;
  height: 10px;
  margin: 15px 2px 0;
  vertical-align: top;
}
.banner-dots li span {
  display: inline-block;
  width: 10px;
  height: 10px;
  text-indent: -9999px;
  background: #ebebeb;
  border-radius: 50%;
  font-size: 0;
  color: transparent;
  cursor: pointer;
  vertical-align: top;
}
.banner-dots li.slick-active span {
  background: #ed1c24;
}

/*.slider_prev{ text-indent:-99999px; font-size:0; color:transparent; display:inline-block; vertical-align:top; background:url(../images/slider-control2.png) no-repeat 0 0; width:38px; height:38px; cursor:pointer; margin-right:20px;}
.slider_next{ text-indent:-99999px; font-size:0; color:transparent; display:inline-block; vertical-align:top; background:url(../images/slider-control2.png) no-repeat 100% 0; width:38px; height:38px; cursor:pointer;margin-left:20px;}*/
/* -- abour GPS end --- */

/* -- abour global start --- */
.aboutGlobalSec2 {
  padding: 50px 0;
  background: #fafafa;
}
.aboutGlobalSec2 h2 {
  text-align: center;
  color: #30323f;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
}
.aboutGlobalSec2 ul {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}
.aboutGlobalSec2 ul:after {
  content: "";
  display: block;
  clear: both;
}
.aboutGlobalSec2 ul li {
  width: 33%;
  min-width: 250px;
  display: inline-block;
  height: 65px;
  padding-left: 80px;
  position: relative;
  color: #30323f;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.aboutGlobalSec2 ul li:after {
  content: "\200B";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.aboutGlobalSec2 ul li span {
  display: inline-block;
  vertical-align: middle;
  color: #30323f;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
}
.aboutGlobalSec2 ul li:before {
  content: "";
  width: 61px;
  height: 61px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}
/*.aboutGlobalSec2 ul li.ps_icon1:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat 0 0;}
.aboutGlobalSec2 ul li.ps_icon2:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat 0 -61px;}
.aboutGlobalSec2 ul li.ps_icon3:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat 0 -122px;}
.aboutGlobalSec2 ul li.ps_icon4:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat 0 -183px;}
.aboutGlobalSec2 ul li.ps_icon5:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat 0 -244px;}
.aboutGlobalSec2 ul li.ps_icon6:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat 0 -305px;}
.aboutGlobalSec2 ul li.ps_icon7:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -61px 0;}
.aboutGlobalSec2 ul li.ps_icon8:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -61px -61px;}
.aboutGlobalSec2 ul li.ps_icon9:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -61px -122px;}
.aboutGlobalSec2 ul li.ps_icon10:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -61px -183px;}
.aboutGlobalSec2 ul li.ps_icon11:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -61px -244px;}
.aboutGlobalSec2 ul li.ps_icon12:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -61px -305px;}
.aboutGlobalSec2 ul li.ps_icon13:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -122px 0;}
.aboutGlobalSec2 ul li.ps_icon14:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -122px -61px;}
.aboutGlobalSec2 ul li.ps_icon15:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -122px -122px;}
.aboutGlobalSec2 ul li.ps_icon16:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -122px -183px;}
.aboutGlobalSec2 ul li.ps_icon17:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -122px -244px;}
.aboutGlobalSec2 ul li.ps_icon18:before{background:#ed1c24 url(../images/products-solutions-icon.png) no-repeat -122px -305px;}*/

.aboutGlobalSec3 {
  padding: 50px 0;
  background: #213468;
}
.aboutGlobalSec3 > div.center {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}
.aboutGlobalSec3 > div:after {
  content: "";
  display: block;
  clear: both;
}
.aboutGlobalSec3 ul {
  float: left;
  width: 50%;
}
.aboutGlobalSec3 ul li {
  padding: 30px 0;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  border-bottom: 1px solid #ed1c24;
  max-width: 270px;
}
.aboutGlobalSec3 ul li:last-child {
  border-bottom: 0px solid #ed1c24;
}
.aboutGlobalSec3 ul li strong {
  display: block;
  color: #ed1c24;
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 15px;
}
.aboutGlobalSec3 aside {
  float: right;
  width: 50%;
  text-align: right;
}
.aboutGlobalSec3 aside img {
  width: 100%;
  max-width: 397px;
}
/*.leftLine{ position:absolute; top:137px; right:100%; display:block; height:250px; background:url(../images/line.png) no-repeat 0 0;}*/
/* -- abour global end --- */

/*----signinForm2-------*/

.signinForm2 {
  margin-top: 35px;
}
.signinForm2:after {
  content: "";
  display: block;
  clear: both;
}
.signinForm2 li {
  float: left;
  width: 100%;
  position: relative;
}
/*.signinForm2 li.unIcon:before{content:''; position:absolute; left:0; top:11px; width:20px; height:20px; background:url(../images/from-icon.png) no-repeat 50% 0px; }
.signinForm2 li.passIcon:before{content:''; position:absolute; left:0; top:11px; width:20px; height:20px; background:url(../images/from-icon.png) no-repeat 50% -20px;}
.signinForm2 li.captcha:before{content:''; position:absolute; left:0; top:11px; width:20px; height:20px; background:url(../images/from-icon.png) no-repeat 50% -40px;}
.signinForm2 li.emailIcon:before{content:''; position:absolute; left:0; top:11px; width:20px; height:20px; background:url(../images/from-icon.png) no-repeat 50% -80px;}*/
.signinForm2 li.captcha {
  float: left;
  width: 50%;
}
.signinForm2 li.captchaImg {
  float: left;
  width: 50%;
  border-bottom: none;
  padding: 20px 0 0;
  margin: 0;
}
.signinForm2 li.captchaImg:after {
  content: "";
  display: block;
  clear: both;
}
.signinForm2 li.captchaImg img {
  float: left;
  position: relative;
  top: -8px;
}
.signinForm2 li.captchaImg a.change_captcha {
  float: left;
  color: #3676bc;
  font-size: 10px;
  width: 30px;
  height: 20px;
  background: url(../images/from-icon.png) no-repeat 50% -60px;
  font-size: 0;
  color: transparent;
  margin-left: 10px;
}
.signinForm2 .styled-input input {
  font-size: 16px;
  color: #30323f;
  position: relative;
  top: 0px;
  border-bottom: 1px solid #3c3784;
  height: 40px;
  padding-left: 0px;
}
.signinForm2 .styled-input label {
  left: 0;
}
.nnr {
  float: right;
  background-color: #fff;
  border-radius: 10px;
  width: 444px;
  height: auto;
  position: relative;
  overflow: hidden;
  margin: 110px 0 0 0;
}
.submitBtn {
  background: #3c3784;
  padding: 0px 15px;
  display: inline-block;
  border-radius: 23px;
  text-align: left;
  color: #fff;
  line-height: 47px;
  height: 47px;
  display: block;
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  position: inherit;
  left: 0px;
  bottom: 0px;
  width: 200px;
}

@media screen and (max-width: 1024px) {
  .content_panel .body-part {
    width: 100%;
  }
  img {
    max-width: 100%;
    height: auto;
    vertical-align: top !important;
  }
  .content_panel .body-part-privacy {
    width: 100%;
    margin: 0px auto 50px auto;
    background: #f5f5f5;
    padding: 90px;
    min-height: 520px;
  }
  .content_panel .body-part ul li article h3 {
    font-size: 19px;
    color: #006600;
    padding: 8px 0;
    line-height: 22px;
  }
}

@media screen and (max-width: 999px) {
  .loginSec1 {
    padding: 100px 0 80px;
  }
}

@media screen and (max-width: 800px) {
  .loginSec1:before {
    display: none;
  }
  .navTrigger {
    display: block;
  }
  .top_panel nav {
    float: none;
    padding: 17px 0 0 0;
    position: fixed;
    top: 0;
    right: -165px;
    width: 165px;
    height: 100%;
    background: #53bf53;
  }
  .top_panel ul#nav li {
    float: none;
    margin: 0 auto;
    border-bottom: none;
    display: block;
    padding: 10px 0;
    height: auto;
    display: inline-block;
    text-align: center;
  }
  .top_panel ul#nav li a {
    color: #fff;
    font-size: 14px;
    line-height: 25px;
  }
  #navHolder ul {
    margin: 0 auto;
    text-align: center;
  }

  .top_panel ul#nav li.signInBtn {
    padding: 0px 0;
    border-bottom: 0px solid rgba(0, 0, 0, 0.2);
    position: relative;
    top: 15px;
  }
  .top_panel ul#nav li.signInBtn a {
    height: 45px;
    line-height: 45px;
  }
  .top_panel ul#nav li.signInBtn a:after {
    top: 16px;
  }

  .loginIntro:before {
    display: none;
  }
  .loginIntro h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .loginIntro {
    float: left;
    width: 100%;
    margin-bottom: 35px;
    clear: both;
    text-align: center;
    padding: 0px 0 0 0;
  }
  .loginIntro ul {
    display: inline-block;
  }
  .loginBox {
    float: none;
    margin: 0 auto;
    clear: both;
  }
  footer aside:first-of-type {
    float: left;
    width: 100%;
    max-width: 685px;
    padding-left: 120px;
    margin-bottom: 30px;
  }
  footer aside:last-of-type {
    float: right;
    width: 100%;
  }
  footer aside:last-of-type ul.footerLink,
  .socialMedia {
    text-align: center;
  }
  .copyright-text {
    color: #fff;
    position: inherit;
    left: 0;
    bottom: 0;
    text-align: center;
    padding: 15px 0 0 0;
  }
  .copyright-text p {
    text-align: center;
    color: #ccc;
  }
  .copyright-text p span {
    text-align: center;
    float: none;
    display: inline-block;
    color: #ccc;
  }
  .copyright-text p i {
    line-height: 22px;
    margin: 22px 3px 0 0;
    display: inline-block;
    float: none;
  }
  .loginIntro h2 span {
    font-size: 35px;
    color: #000;
    line-height: 30px;
    font-weight: 600;
    display: block;
  }
  .content_panel .body-part-privacy {
    width: 100%;
    margin: 0px auto 50px auto;
    background: #f5f5f5;
    padding: 40px;
    min-height: 520px;
  }
  .content_panel .body-part-privacy article h1 {
    font-size: 36px;
    line-height: 48px;
    color: #0d8924;
    font-weight: 600;
    padding: 0 0 40px 0;
  }
  .content_panel .body-part ul li article h3 {
    font-size: 16px;
    color: #006600;
    padding: 8px 0;
    line-height: 22px;
  }
  .content_panel .body-part ul li article p {
    font-size: 15px;
    color: #000;
    line-height: 23px;
  }
  .content_panel .about-part article {
    display: inline-block;
    width: 44%;
    text-align: left;
    padding: 40px 0;
  }
  .top_panel div.logo {
    float: none;
    padding-left: 20px;
    padding-top: 20px;
  }
}

@media screen and (max-width: 700px) {
  .aboutGlobalSec3 ul {
    float: none;
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
  }
  .aboutGlobalSec3 aside {
    float: right;
    width: 100%;
    text-align: center;
  }
  .leftLine {
    display: none;
  }

  .aboutGpsTabCont ul li {
    float: left;
    width: 100%;
    padding: 0 0 15px 0;
    margin-bottom: 15px;
    border-right: 0px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
  }
  .aboutGpsTabCont ul li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0px solid #ebebeb;
  }
}

@media screen and (max-width: 600px) {
  footer aside:first-of-type {
    padding-left: 0;
    text-align: center;
  }
  footer aside:first-of-type img.footerLogo {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 20px;
  }
  .aboutGpsTabCont ul li:last-child {
    padding-bottom: 25px;
  }
  .aboutGpsTab li {
    font-size: 15px;
  }
  .aboutGpsTabCont section h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #30323f;
    line-height: 28px;
    font-weight: 600;
  }
  .content_panel .body-part {
    width: 100%;
    margin: 60px auto 0 auto;
  }
  .content_panel .body-part ul li {
    width: 100%;
    margin: 0;
    display: block;
  }
  .content_panel .body-part ul li article {
    text-align: center;
  }
  .content_panel .body-part ul li article a {
    padding: 0px 0 0 0;
    display: block;
    color: #006600;
    position: relative;
    width: 127px;
    line-height: 7px;
    margin: 30px auto;
    display: block;
    text-decoration: none;
  }
  .content_panel .body-part-privacy {
    width: 100%;
    margin: 0px auto 50px auto;
    background: #fff;
    padding: 30px;
    min-height: auto;
  }
  .content_panel .body-part-privacy article {
    width: 100%;
    float: none;
  }
  .content_panel .body-part-privacy article h1 {
    font-size: 36px;
    line-height: 48px;
    color: #0d8924;
    font-weight: 600;
    padding: 0 0 40px 0;
    text-align: center;
  }
  .content_panel .body-part-privacy aside {
    width: 100%;
    float: none;
    padding: 80px 0 0 0;
    margin: 0 auto;
    text-align: center;
  }
  .content_panel .about-part article {
    display: block;
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
  .content_panel .body-part .about {
    width: auto;
    text-align: center;
    padding: 0 30px;
  }
  .content_panel .about-part article a {
    font-size: 16px;
    color: #0d8924;
    padding: 0px 0 0 0;
    display: block;
    position: relative;
    width: 137px;
    line-height: 7px;
    margin: 20px auto;
    display: block;
    text-decoration: none;
    color: #006600;
  }
  .signin-center {
    width: auto;
    margin: 0 auto;
  }
  .content_panel .about-part .signup {
    width: 350px;
    height: 125px;
    background: #f6f6f6;
    text-align: left;
    margin: 10px auto;
    padding: 30px;
    float: none;
    vertical-align: top;
  }
  .content_panel .body-part-privacy article p {
    font-size: 18px;
    color: #000;
    line-height: 23px;
    padding: 0 0 25px 0;
    text-align: center;
  }
  .footer-copy .pull-left {
    float: none !important;
  }
  .footer-copy .pull-right {
    float: none !important;
  }
  .footer ul.footer-menu li {
    margin-bottom: 0px;
    width: 100%;
    float: none;
  }
  .top_panel ul#nav li:nth-child(1) {
    background: none;
    padding: 10px;
  }
  .top_panel ul#nav li:nth-child(2) {
    background: none;
    padding: 10px;
  }
  .top_panel ul#nav li:nth-child(3) {
    background: none;
    padding: 10px;
  }
  .content_panel .body-part-privacy a {
    position: relative;
    width: 97px;
    line-height: 7px;
    margin: 30px auto;
    display: block;
    text-decoration: none;
    color: #006600;
  }
  .top_panel div.logo {
    float: inherit;
    height: 100%;
    vertical-align: middle;
    padding-left: 10px;
  }
  .navTrigger {
    position: absolute;
    top: 0px;
    z-index: 9;
    right: 10px;
  }
  .top_panel nav {
    float: none;
    padding: 0px 0 0 0;
    position: initial;
    top: 0;
    right: 0;
    width: auto;
    height: 42px;
    background: #24215f;
  }
  .top_panel {
    background-color: #000;
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 66px;
    box-shadow: 0px 5px 10px -3px rgb(0 0 0 / 20%);
  }
  .top_panel div.logo {
    float: inherit;
    height: 100%;
    vertical-align: middle;
    padding-left: 10px;
    text-align: center;
  }
  .loginSec1 {
    padding: 130px 0 80px;
  }
}
@media screen and (max-width: 480px) {
  .loginBox {
    width: 350px;
  }
  .loginIntro ul li {
    padding: 65px 9px 0;
  }
  .copyright-text p i {
    display: none;
  }
  .copyright-text p {
    padding: 0 15px;
  }
  .loginIntro {
    float: left;
    width: 100%;
    text-align: center;
    padding: 0px 0 0 0 !important;
  }
}
